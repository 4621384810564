import {
  For,
  createEffect,
  createResource,
  createSignal,
  onMount,
} from "solid-js";
import ExternalBlogLink from "~/components/shared/Links/BlogLink";
import ArticleCard from "~/components/specific/Blog/ArticleCard";
import CardLayout from "~/layouts/CardLayout";
import { BLOG_FEED_URL, fetchLatest, formatPosts } from "~/utils/helpers";

function Blog() {
  const [totalPosts, setTotalPosts] = createSignal<number>(-1);
  const [loaded, setLoaded] = createSignal<boolean>(false);

  const [posts, { refetch }] = createResource(
    BLOG_FEED_URL,
    async (url, { refetching }) => {
      // Check if localStorage is defined
      if (typeof localStorage !== "undefined") {
        // Try to get the data from localStorage
        const cachedData = localStorage.getItem(url);
        if (cachedData && !refetching) {
          try {
            const { data, timestamp } = JSON.parse(cachedData);
            setTotalPosts(data.items.length);

            if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
              return formatPosts(data.items);
            }
          } catch (error) {
            console.error("Error parsing data:", error);
          }
        }
      }

      // Fetch new data and store it in localStorage with a timestamp
      try {
        const data = await fetchLatest(BLOG_FEED_URL);
        setTotalPosts(data.items.length);
        if (data) {
          // Check if localStorage is defined
          if (typeof localStorage !== "undefined") {
            localStorage.setItem(
              url,
              JSON.stringify({ data: data, timestamp: Date.now() }),
            );
          }
          return formatPosts(data.items);
        } else {
          console.error("Fetched data is undefined");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    {
      initialValue: Array(6).fill({
        title: "",
        description: "",
        text: "",
        url: "",
      }),
      ssrLoadFrom: "server",
    },
  );
  onMount(() => {
    setLoaded(true);
    refetch();
  });
  createEffect(() => {});
  return (
    <CardLayout
      title="On the Blog"
      loading={posts.loading}
      classList={["blog-card"]}
    >
      <div class="flex text-sm lg:text-base h-10">
        {loaded() && (
          <ExternalBlogLink
            count={totalPosts() as number}
            loading={totalPosts() > 0}
          />
        )}
      </div>
      <br />
      <section>
        <ol
          class="grid-auto-fit cols-1 md:cols-2 lg:cols-3 flow flow-list"
          role="list"
        >
          <For each={posts() as []}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            {(post: any) => (
              <li class="lg:m-1 outline-none" role="listitem">
                <ArticleCard
                  path={post.path}
                  title={post.title}
                  date={post.text}
                  // FIXME
                  tags={post.tags}
                  description={post.description}
                  time={post.reading_time}
                />
              </li>
            )}
          </For>
        </ol>
      </section>
    </CardLayout>
  );
}
export default Blog;
