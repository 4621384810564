import { useContext } from "solid-js";
import TransitionContext from "~/components/shared/Transition/TransitionContext";

function SiteLink(props: { text: string }) {
  const context = useContext(TransitionContext);
  return (
    <a
      data-defer
      href="/about"
      onClick={(event) => {
        event.preventDefault();
        context?.startTransition?.("/about");
      }}
      style={{ "--color": "var(--elevate3)" }}
      class="jl-underline text4 cursor-highlight-pointer-dark hover:wavy-underline hover:text-neutral-55"
    >
      <span class="absolute inset-0" aria-hidden="true" />
      {props.text}
      <span aria-hidden="true">&nbsp;&rarr;</span>
    </a>
  );
}

export default SiteLink;
