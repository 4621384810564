import { Link, Title } from "@solidjs/meta";
import { For } from "solid-js";
import Blog from "~/components/specific/(home)/Cards/Blog";
import Profile from "~/components/specific/(home)/Cards/Profile";
import GridCardItem from "~/components/ui/Grid/GridLayoutItem";
import { sitepaths } from "~/configs/paths";
const meta = {
  title: `${sitepaths.home.title} | Jonathan Lau`,
  canonical: `${import.meta.env.VITE_APP_BASE_URL}${sitepaths.home.path}`,
};

const Index = () => {
  const gridItems = [
    {
      order: [0, 0],
      rowSpan: 1,
      colSpan: 4,
      child: <Profile />,
      bg: "surface3",
      path: null,
    },
    {
      order: [3, 0],
      rowSpan: 4,
      colSpan: 4,
      bg: "",
      child: <Blog />,
    },
  ];

  return (
    <>
      <>
        <Title>{meta.title}</Title>
        <Link rel="canonical" href={`${meta.canonical}`} />
      </>

      <div id="skiplink-content" class="jl-grid">
        <For each={gridItems}>
          {({ order, rowSpan, colSpan, child, path, bg }) => {
            return (
              <GridCardItem
                order={order}
                rowSpan={rowSpan}
                colSpan={colSpan}
                bg={bg}
              >
                {path && sitepaths[path as SitePathKey] ? (
                  <a
                    href={sitepaths[path as SitePathKey].path}
                    // class="cursor-highlight-pointer-dark"
                  >
                    {child}
                  </a>
                ) : (
                  child
                )}
              </GridCardItem>
            );
          }}
        </For>
      </div>
    </>
  );
};

export default Index;
