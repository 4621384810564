import { createEffect, createSignal } from "solid-js";
import { SitePath } from "~/configs/paths";
import FaArrowRightLong from "~/assets/svg/fa-arrow-right-long.svg?component-solid";
// const ACTIVE_CLASS: { [key: number]: string } = {
//   0: "underline underline-offset-2 decoration-wavy decoration-primary-200 text-neutral-50 indent-3 md:indent-4 ",
// };
export function ArticleCard(props: {
  title: string;
  tags: Array<SitePath>;
  path: string;
  description: string;
  date: string;
  time: string;
}) {
  const [active, setActive] = createSignal(false);
  const [isLoading, setIsLoading] = createSignal(true);
  createEffect(() => {
    setIsLoading(!props.title && !props.description && !props.date);
  });

  return (
    // <div class="divide-y divide-gray-200 overflow-hidden rounded-lg surface2 shadow relative pretty-text">
    <article
      class="group relative rounded-md flex flex-col items-start justify-between surface2 lg:border-ink cursor-pointer cursor-highlight-pointer px-5 lg:px-3 lg:min-h-72"
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <div class="max-w-xl">
        <div class="mt-8 flex items-center gap-x-4 text-xs">
          <time datetime={props.date}>{props.date}</time>
        </div>
        <div class="group relative">
          <h4 class="mt-3 text-base lg:text-lg font-semibold leading-6 text3 group-hover:text-primary-200 text-pretty line-clamp-2 h-12">
            <a href={props.path}>
              <span class="absolute inset-0" />
              {props.title}
            </a>
          </h4>
          <div class="hidden lg:block h-24">
            <CardBody text={props.description} />
          </div>
        </div>
      </div>
      <div class="py-4 h-12 relative w-full">
        {!isLoading() && <CardFooter time={props.time} active={active()} />}
      </div>
    </article>
  );
}
function CardFooter(props: { time: string; active: boolean }) {
  return (
    <div class="ml-auto flex justify-between lg:items-end w-full text-xs ">
      <span class={`md:whitespace-nowrap text3`}>
        &#8987;&nbsp;{`${props.time} min read`}
      </span>
      <span
        aria-hidden="true"
        class="float-right"
        aria-label="Arrow indicating link to resume"
      >
        <FaArrowRightLong />
      </span>

      {/* <span
        class={`hidden lg:block md:whitespace-nowrap ${props.active ? ACTIVE_CLASS[0] : ""}`}
      >
        <div class="hidden md:block absolute -bottom-4 -right-2 rounded-full backdrop-brightness-50">
          <ArrowIcon show={active()} rotate0={!active()} rotate90={active()} />
        </div>
      </span> */}
    </div>
  );
}

function CardBody(props: { text: string }) {
  {
    /* <Quote
    class="absolute top-3 left-0"
    style={{ "--fill": "var(--text4)" }}
  /> */
  }
  return (
    <p class="mt-5 line-clamp-3 text-sm text4 lg:text-base leading-6 ff-serif">
      {props.text}
    </p>
  );
}

export default ArticleCard;
