import { JSXElement, splitProps } from "solid-js";
export interface GridLayoutProps {
  children: JSXElement;
  colSpan: number;
  rowSpan: number;
  darkMode?: boolean;
  isVisible?: boolean;
  order?: unknown[];
  bg?: string;
}

export default function GridCardItem(props: GridLayoutProps) {
  const [local] = splitProps(props, [
    "children",
    "order",
    "rowSpan",
    "colSpan",
  ]);

  const COL_SPANS: { [key: number]: string } = {
    1: "col-span-1",
    2: "col-span-1",
    3: "col-span-1",
    4: "col-span-1",
    5: "col-span-1",
    // …
  };

  const LG_COL_SPANS: { [key: number]: string } = {
    1: "lg:col-span-1",
    2: "lg:col-span-2",
    3: "lg:col-span-3",
    4: "lg:col-span-4",
    5: "lg:col-span-5",
    // …
  };
  const ROW_SPANS: { [key: number]: string } = {
    1: "row-span-1",
    2: "row-span-1",
    3: "row-span-1",
    4: "row-span-1",
    5: "row-span-1",
    // …
  };

  const LG_ROW_SPANS: { [key: number]: string } = {
    1: "lg:row-span-1",
    2: "lg:row-span-2",
    3: "lg:row-span-3",
    4: "lg:row-span-4",
    5: "lg:row-span-5",
    // …
  };

  return (
    <div
      class={`grid-listitem border-ink border-solid rounded-4 ${COL_SPANS[local.colSpan]} ${LG_COL_SPANS[local.colSpan]} ${ROW_SPANS[local.rowSpan]} ${LG_ROW_SPANS[local.rowSpan]} ${props.bg}`}
    >
      <div class="jl-centered">{local.children}</div>
    </div>
  );
}
