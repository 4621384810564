import content from "~/data/content.json";
import profile from "~/img/profile.webp";
import { Suspense, lazy } from "solid-js";
import SiteLink from "../ui/SiteLink";
const metadata = content.profileData;
const GeoText = lazy(() => import("~/styles/welcome.svg?component-solid"));

export default function Profile() {
  return (
    <div class="mx-auto max-w-5xl pb-8 pt-8 sm:pb-8 flex flex-col lg:flex-row p-8">
      <div class="jl-centered mt-8 mx-auto lg:mx-10">
        <img
          class="img size-step-9"
          src={profile}
          width="200px"
          height="200px"
          alt="Self-portrait"
          crossOrigin=""
        />
      </div>
      <div class="lg:py-12 jl-start">
        <div class="mx-auto max-w-7xl lg:px-8">
          <div class="mx-auto my-4 max-w-2xl lg:mx-0">
            <h2 class="leading-10 text-lg lg:text-3xl">Hi, I'm Jonathan.</h2>
            <p class="mt-6 text-base lg:text-lg leading-relaxed ff-mono text2">
              {metadata.title}
            </p>
          </div>
          <div class="relative rounded-full px-1 py-1 text-sm leading-6 surface1 ff-sans-serif">
            More <SiteLink text="about me" />
          </div>
          <div class="max-w-2xl lg:mx-0 flex flex-col justify-start items-start">
            <Suspense fallback={<div class="w-64 h-16 max-h-16" />}>
              <GeoText
                class="max-lg:max-w-48 lg:w-56 h-16 fill-current"
                aria-label="Based in Fremont, California"
              />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}
