import { createEffect, createSignal, Show } from "solid-js";
import { externalLinkPath } from "~/configs/paths";
import { getEncodedPath } from "~/utils/helpers";
import LinkIcon from "~/assets/svg/link.svg?component-solid";

function ExternalBlogLink(props: { count: number; loading: boolean }) {
  const _count = () => props.count;
  const [count, setCount] = createSignal(_count());

  createEffect(() => {
    setCount(props.count);
  });

  const rxhref = getEncodedPath(externalLinkPath);
  return (
    <Show when={count() > 0} fallback={<>...</>}>
      <span class="relative px-3 py-1 leading-6 text-base">
        View all{" "}
        <a
          data-defer
          href={rxhref}
          class="md:whitespace-nowrap text-primary-200 jl-underline"
        >
          <span class="absolute inset-0 inline" aria-hidden="true" />
          {count()} posts
          <span aria-hidden="true" class="inline">
            &nbsp;
            <LinkIcon
              class="inline text-primary-200"
              style={{ "--fill": "var(--text4)" }}
            />
          </span>
        </a>
      </span>
    </Show>
  );
}

export default ExternalBlogLink;
